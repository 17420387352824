import { PrescriptionLine } from '~/models/PrescriptionLine'
import { PullService } from '../servicePull'
import { PrescriptionLineTotalAmountInfoModel } from '../../models/prescriptionLineTotalAmountInfoModel'
import { getDocs, where, query } from 'firebase/firestore'
import { InsuranceCompany, MedicineArticle } from '~/models'
import { CodeType } from '~/models/PrescriptionLineContent'
import { Controller } from '~/features/controller'
import { ControllerState } from '~/features/controllerState'
import { ControllerStateArray } from '~/features/controllerStateArray'

export class PrescriptionLineTotalAmountInfoService extends PullService<
  PrescriptionLineTotalAmountInfoModel,
  PrescriptionLineTotalAmountInfoModel[]
> {
  private collection

  override createControllerState(
    controller: Controller,
    sideEffect?: () => void
  ): ControllerState<
    PrescriptionLineTotalAmountInfoModel,
    PrescriptionLineTotalAmountInfoModel[]
  > {
    return new ControllerStateArray(controller, sideEffect)
  }

  constructor() {
    super()
    const { collection } = useFirebase()
    this.collection = collection
  }

  public async getMedicineArticleByCodeType(code: number, codeType: CodeType) {
    let articleQuery
    if (codeType === CodeType.KNMP) {
      articleQuery = query(
        this.collection('MedicineArticles'),
        where('ArticleNumber', '==', code)
      )
    }

    if (!articleQuery) {
      console.log('Invalid codeType: ' + codeType)
      return null
    }

    const { docs } = await getDocs(articleQuery)
    if (docs.length > 0) {
      return docs[0].data() as MedicineArticle
    }
    return null
  }

  public async getInsuranceCompanyByUzovi(
    uzovi: string
  ): Promise<InsuranceCompany | undefined> {
    const queryInsuranceCompany = query(
      this.collection('InsuranceCompanies'),
      where('Uzovi', '==', uzovi)
    )

    const { docs } = await getDocs(queryInsuranceCompany)

    if (docs.length === 0) {
      return undefined
    }

    return docs[0].data() as InsuranceCompany
  }

  @PullService.CallableFunction<PrescriptionLineTotalAmountInfoModel[]>()
  public async getPrescriptionLineTotalAmountInfo(
    prescriptionLine: PrescriptionLine
  ): Promise<PrescriptionLineTotalAmountInfoModel[]> {
    const medicationPrescriptionLine = prescriptionLine.Content.Medication

    const medicineArticle = await this.getMedicineArticleByCodeType(
      Number(medicationPrescriptionLine.Code),
      medicationPrescriptionLine.CodeType
    )

    if (!medicineArticle) {
      console.log(
        `Medicine article, ${medicationPrescriptionLine.CodeType}: ${medicationPrescriptionLine.Code} not found`
      )
      return []
    }

    const uzovi = prescriptionLine.Content.Patient.InsurerCode
    const insuranceCompany = await this.getInsuranceCompanyByUzovi(uzovi)

    if (!insuranceCompany) {
      console.log('Insurance company not found with uzovi' + uzovi)
      return []
    }

    return [
      {
        id: prescriptionLine.id,
        medicineArticle,
        insuranceCompany
      }
    ]
  }
}
