
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexeH_45knvsPxuzKCtuhxbzmq3A52gxj9sNHQK_MhXjK724Meta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as printenPR4O5xaWyfUNHw9dDocP_F6dcurk6qCwp_454_45ySC15eUMeta } from "/opt/atlassian/pipelines/agent/build/pages/printen.vue?macro=true";
import { default as accountsI2_I_45kbf5mm7ewezyqeFDtxyeFHBxDpUP6zNa1yvbrEMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts.vue?macro=true";
import { default as inloggenwHLDgWyIMgJrtRcOlOPfaWvorHcLv_HZktyh_QMguxUMeta } from "/opt/atlassian/pipelines/agent/build/pages/inloggen.vue?macro=true";
import { default as nazendingen_45lP8tUSwgFOzYXha3QYjGZgaz_45262i1rqCo3EPxCIa8Meta } from "/opt/atlassian/pipelines/agent/build/pages/nazendingen.vue?macro=true";
import { default as bestellingenWt4Cl3ulJtVsYS_5FWTgGI4V2Oviprf7D8dMV2JXmJAMeta } from "/opt/atlassian/pipelines/agent/build/pages/bestellingen.vue?macro=true";
import { default as klantprofielihBDQWvdkhhIzd_45Jl4mxJ9jvVkfUFkzxUoB9lU0BcMEMeta } from "/opt/atlassian/pipelines/agent/build/pages/klantprofiel.vue?macro=true";
import { default as receptverwerkingQnQhRtWwUSHstxJwrCmcVtRx2L8ZXc4S4fXXqVIzaYAMeta } from "/opt/atlassian/pipelines/agent/build/pages/receptverwerking.vue?macro=true";
import { default as bestanden_45inladenrl1v266hlxU5MY4mwX2hFtAniZxkoXCC4ybkOjqyHlQMeta } from "/opt/atlassian/pipelines/agent/build/pages/bestanden-inladen.vue?macro=true";
import { default as controle_45leveringOIclme3NfnhvR6NNOI_Jk2uZpMDB_45h031o6UMsGITdYMeta } from "/opt/atlassian/pipelines/agent/build/pages/controle-levering.vue?macro=true";
import { default as parallel_45bestellenfFTxuBJps7TGTIyuCuJY2wcWe40XaN7E_4513Y1OEWNqgMeta } from "/opt/atlassian/pipelines/agent/build/pages/parallel-bestellen.vue?macro=true";
import { default as apothekers_45controlehH6XfdYa6SRL5kpORRZ0tRKrzgeHG0IdrdT8aClDnFUMeta } from "/opt/atlassian/pipelines/agent/build/pages/apothekers-controle.vue?macro=true";
import { default as leveringen_45overzichtoul8kDdOR9_45GH1UQw5BVbvf4G44bvBkWG8RIhEpz0JsMeta } from "/opt/atlassian/pipelines/agent/build/pages/leveringen-overzicht.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexeH_45knvsPxuzKCtuhxbzmq3A52gxj9sNHQK_MhXjK724Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "printen",
    path: "/printen",
    meta: printenPR4O5xaWyfUNHw9dDocP_F6dcurk6qCwp_454_45ySC15eUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/printen.vue")
  },
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts.vue")
  },
  {
    name: "inloggen",
    path: "/inloggen",
    meta: inloggenwHLDgWyIMgJrtRcOlOPfaWvorHcLv_HZktyh_QMguxUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/inloggen.vue")
  },
  {
    name: "nazendingen",
    path: "/nazendingen",
    meta: nazendingen_45lP8tUSwgFOzYXha3QYjGZgaz_45262i1rqCo3EPxCIa8Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/nazendingen.vue")
  },
  {
    name: "bestellingen",
    path: "/bestellingen",
    meta: bestellingenWt4Cl3ulJtVsYS_5FWTgGI4V2Oviprf7D8dMV2JXmJAMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/bestellingen.vue")
  },
  {
    name: "klantprofiel",
    path: "/klantprofiel",
    meta: klantprofielihBDQWvdkhhIzd_45Jl4mxJ9jvVkfUFkzxUoB9lU0BcMEMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/klantprofiel.vue")
  },
  {
    name: "receptverwerking",
    path: "/receptverwerking",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/receptverwerking.vue")
  },
  {
    name: "bestanden-inladen",
    path: "/bestanden-inladen",
    meta: bestanden_45inladenrl1v266hlxU5MY4mwX2hFtAniZxkoXCC4ybkOjqyHlQMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/bestanden-inladen.vue")
  },
  {
    name: "controle-levering",
    path: "/controle-levering",
    meta: controle_45leveringOIclme3NfnhvR6NNOI_Jk2uZpMDB_45h031o6UMsGITdYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/controle-levering.vue")
  },
  {
    name: "parallel-bestellen",
    path: "/parallel-bestellen",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/parallel-bestellen.vue")
  },
  {
    name: "apothekers-controle",
    path: "/apothekers-controle",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/apothekers-controle.vue")
  },
  {
    name: "leveringen-overzicht",
    path: "/leveringen-overzicht",
    meta: leveringen_45overzichtoul8kDdOR9_45GH1UQw5BVbvf4G44bvBkWG8RIhEpz0JsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/leveringen-overzicht.vue")
  }
]