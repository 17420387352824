import { Service } from './service'
import { Controller } from '~/features/controller'
import { ControllerStateArray } from '~/features/controllerStateArray'
import { ControllerState } from '~/features/controllerState'
import { Identifiable } from '~/models/identifiable'

export abstract class PullService<
  DataType extends Identifiable,
  ReturnType extends DataType[]
> extends Service<DataType, ReturnType> {
  override createControllerState(
    controller: Controller,
    sideEffect?: () => void
  ): ControllerState<DataType, ReturnType> {
    return new ControllerStateArray(controller, sideEffect) as ControllerState<
      DataType,
      ReturnType
    >
  }

  /**
   * Decorator that wraps the annotated function to call the updateState method of the service.
   */
  public static CallableFunction<ReturnType>() {
    return function (
      target: any,
      propertyKey: string,
      descriptor: PropertyDescriptor
    ) {
      const originalfunction = descriptor.value
      descriptor.value = async function (...args: any[]) {
        const service = this as PullService<any, ReturnType[]>
        const data = await originalfunction.apply(this, args, true)
        service.updateSubscriberStates(data)
      }
    }
  }
}
