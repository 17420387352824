import { Identifiable } from '~/models/identifiable'

type Paths<T extends object> = {
  [K in keyof T]: T[K] extends object ? [K, ...Paths<T[K]>] : [K]
}[keyof T]

export abstract class StateChange<T extends object, U> {
  private _id: string
  protected value: U

  constructor(id: string, value: U) {
    this._id = id
    this.value = value
  }

  abstract isDifferent(source: T): boolean

  get id(): string {
    return this._id
  }
}

export class StateUpdate<T extends Identifiable, U> extends StateChange<T, U> {
  private path: Paths<T>

  constructor(id: string, path: Paths<T>, value: U) {
    super(id, value)
    this.path = path
  }

  override isDifferent(source: T): boolean {
    return _get(source, this.path[0]) !== this.value
  }

  apply(source: T): T {
    return _set({ ...source }, this.path[0], this.value) as T
  }
}

export class StateAdd<T extends Identifiable> extends StateChange<T, T> {
  constructor(value: T) {
    super(value.id, value)
  }

  override isDifferent(source: T): boolean {
    return source.id !== this.value.id
  }

  getValue(): T {
    return this.value
  }
}

export class StateChangeRemove<T extends Identifiable> extends StateChange<
  T,
  T
> {
  constructor(value: T) {
    super(value.id, value)
  }

  override isDifferent(source: T): boolean {
    return source.id !== this.value.id
  }
}
