import { RuntimeConfig } from 'nuxt/schema'
import { PullService } from './servicePull'
import { Controller } from '~/features/controller'
import { ControllerState } from '~/features/controllerState'
import { ControllerStateArray } from '~/features/controllerStateArray'
import { Identifiable } from '~/models/identifiable'
export class ParallelOrderService extends PullService<
  GoogleCloudFolder,
  GoogleCloudFolder[]
> {
  override createControllerState(
    controller: Controller,
    sideEffect?: () => void
  ): ControllerState<GoogleCloudFolder, GoogleCloudFolder[]> {
    return new ControllerStateArray(controller, sideEffect)
  }
  private config: RuntimeConfig
  private apiGet
  private reportError

  constructor() {
    super()
    this.config = useRuntimeConfig()
    const { apiGet } = useApi()
    this.apiGet = apiGet
    const { reportError } = useSentry()
    this.reportError = reportError
  }

  public async downloadFilesInFolder(folderName: string) {
    const files = await this.apiGet<Blob>('parallel-order/folder/files', {
      query: {
        folderName,
        bucketName: this.config.public.GC_WHOLESALE_BUCKET
      }
    })

    this.downloadBlob(files, folderName + '.zip')
  }

  //Ignore this function from coverage because its functionalities are too specific
  /* v8 ignore start */
  private downloadBlob(blob: Blob, fileWithExtension: string) {
    try {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url

      link.setAttribute('download', fileWithExtension)

      document.body.appendChild(link)

      link.click()

      link.parentNode?.removeChild(link)
    } catch (error) {
      this.reportError('Error downloading file', {
        error,
        fileWithExtension
      })
    }
  }
  /* v8 ignore stop */

  @PullService.CallableFunction<GoogleCloudFolder[]>()
  public async getFoldersInBucket(): Promise<GoogleCloudFolder[]> {
    const apiFolders = await this.apiGet<GCFolderAPI[]>(
      'parallel-order/folders',
      {
        query: {
          maxNumberOfFolders: 10,
          bucketName: this.config.public.GC_WHOLESALE_BUCKET
        }
      }
    )

    if (Array.isArray(apiFolders) === false) {
      return []
    }

    const folders = apiFolders.map(
      (folder) =>
        ({
          ...folder,
          startDate: folder.startDate ? new Date(folder.startDate) : undefined,
          endDate: folder.endDate ? new Date(folder.endDate) : undefined
        }) as GoogleCloudFolder
    )

    return folders
  }
}

type GCFolderAPI = {
  name: string
  startDate?: string
  endDate?: string
}

export type GoogleCloudFolder = Identifiable & {
  name: string
  startDate?: Date
  endDate?: Date
}
