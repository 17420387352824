import { Identifiable } from '~/models/identifiable'
import { Controller } from './controller'
import { ControllerState } from './controllerState'

export class ControllerStateArray<
  Item extends Identifiable
> extends ControllerState<Item, Item[]> {
  constructor(controller: Controller, sideEffect?: () => void) {
    super(controller, sideEffect)
  }

  override getEmptyState(): Item[] {
    return []
  }

  override keepLocalChanges(newstate: Item[]): Item[] {
    if (
      Object.keys(this.localChanges.added).length === 0 &&
      Object.keys(this.localChanges.removed).length === 0 &&
      Object.keys(this.localChanges.updated).length === 0
    ) {
      return newstate
    }

    let result = [...newstate]
    result = this.handleStateUpdate(result)
    result = this.handleStateAdd(result)
    result = this.handleStateRemove(result)

    return result
  }

  override handleStateUpdate(newState: Item[]): Item[] {
    if (Object.keys(this.localChanges.updated).length === 0) {
      return newState
    }

    return newState.map((change) => {
      const localChange = this.localChanges.updated[change.id]
      if (localChange === undefined) {
        return change
      }

      if (localChange.isDifferent(change)) {
        return localChange.apply(change)
      }

      delete this.localChanges.updated[change.id]

      return change
    })
  }

  override handleStateAdd(newState: Item[]): Item[] {
    if (Object.keys(this.localChanges.added).length === 0) {
      return newState
    }

    const ids = Object.keys(this.localChanges.added)

    for (const item of newState) {
      if (ids.includes(item.id)) {
        delete this.localChanges.added[item.id]
      }
    }

    for (const localChange of Object.values(this.localChanges.added)) {
      newState.push(localChange.getValue())
    }

    return newState
  }

  override handleStateRemove(newState: Item[]): Item[] {
    if (Object.keys(this.localChanges.removed).length === 0) {
      return newState
    }

    const idsToRemove = new Set(Object.keys(this.localChanges.removed))

    const result = []
    for (const item of newState) {
      if (idsToRemove.has(item.id)) {
        idsToRemove.delete(item.id)
        continue
      }

      result.push(item)
    }

    for (const id of idsToRemove) {
      delete this.localChanges.removed[id]
    }

    return result
  }

  override applyLocalChanges() {
    if (!this.data) {
      return
    }

    this.data = this.data.map((item) => {
      const localChange = this.localChanges.updated[item.id]
      if (localChange === undefined) {
        return item
      }

      return localChange.apply(item)
    })

    for (const localChange of Object.values(this.localChanges.added)) {
      this.data.push(localChange.getValue())
    }

    const ids = Object.keys(this.localChanges.removed)
    this.data = this.data.filter((item) => !ids.includes(item.id))
  }
}
