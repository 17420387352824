import { Identifiable } from '~/models/identifiable'
import { Controller } from './controller'
import { ControllerState } from './controllerState'

export class ControllerStateRecord<
  Item extends Identifiable
> extends ControllerState<Item, Record<string, Item>> {
  constructor(controller: Controller, sideEffect?: () => void) {
    super(controller, sideEffect)
  }

  override getEmptyState(): Record<string, Item> {
    return {}
  }

  override keepLocalChanges(
    changes: Record<string, Item>
  ): Record<string, Item> {
    if (
      Object.keys(this.localChanges.added).length === 0 &&
      Object.keys(this.localChanges.removed).length === 0 &&
      Object.keys(this.localChanges.updated).length === 0
    ) {
      return changes
    }

    let result = this.handleStateUpdate(changes)
    result = this.handleStateAdd(result)
    result = this.handleStateRemove(result)

    return result
  }

  override handleStateUpdate(
    newState: Record<string, Item>
  ): Record<string, Item> {
    if (Object.keys(this.localChanges.updated).length === 0) {
      return newState
    }

    const keys = Object.keys(newState)
    const result: Record<string, Item> = {}
    for (const key of keys) {
      const change = newState[key]
      const localChange = this.localChanges.updated[key]

      if (localChange === undefined) {
        result[key] = change
        continue
      }

      if (localChange.isDifferent(change)) {
        result[key] = localChange.apply(change)
        continue
      }

      delete this.localChanges.updated[key]

      result[key] = change
    }

    return result
  }

  override handleStateAdd(
    newState: Record<string, Item>
  ): Record<string, Item> {
    if (Object.keys(this.localChanges.added).length === 0) {
      return newState
    }

    const keys = Object.keys(this.localChanges.added)

    for (const key of keys) {
      if (!!newState[key]) {
        delete this.localChanges.added[key]
        continue
      }

      newState[key] = this.localChanges.added[key].getValue()
    }

    return newState
  }

  override handleStateRemove(
    newState: Record<string, Item>
  ): Record<string, Item> {
    if (Object.keys(this.localChanges.removed).length === 0) {
      return newState
    }

    const keys = Object.keys(this.localChanges.removed)

    for (const key of keys) {
      if (newState[key] === undefined) {
        delete this.localChanges.removed[key]
      }

      delete newState[key]
    }

    return newState
  }

  override applyLocalChanges() {
    const keys = Object.keys(this.data)
    for (const key of keys) {
      const item = this.data[key]
      const localChange = this.localChanges.updated[key]

      if (localChange === undefined) {
        return
      }

      this.data[key] = localChange.apply(item)
    }
  }
}
