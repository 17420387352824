import { PageName } from '~/config'
import { Controller } from './controller'
import {
  StateChange,
  StateAdd,
  StateChangeRemove,
  StateUpdate
} from './stateChange'
import { Identifiable } from '~/models/identifiable'

export abstract class ControllerState<
  Item extends Identifiable,
  ItemCollection extends Item[] | Record<string, Item>
> {
  protected data!: ItemCollection
  private loading: boolean
  private sideEffect?: () => void
  private update: () => void
  public readonly pageName: string
  private _isActive: boolean

  protected localChanges: {
    added: Record<string, StateAdd<Item>>
    removed: Record<string, StateChangeRemove<Item>>
    updated: Record<string, StateUpdate<Item, any>>
  } = {
    added: {},
    removed: {},
    updated: {}
  }

  public get isActive(): boolean {
    return this._isActive
  }

  constructor(controller: Controller, sideEffect?: () => void) {
    this.update = controller.update.bind(controller)
    this.pageName = controller.pageName
    this.sideEffect = sideEffect?.bind(controller)
    this.loading = true
    this._isActive = true
  }

  activatePage(pageName: PageName) {
    if (pageName === this.pageName) {
      this._isActive = true
      return
    }
    this._isActive = false
  }

  public setLocalState(stateChanges: StateChange<Item, any>[]) {
    for (const change of stateChanges) {
      if (change instanceof StateAdd) {
        this.localChanges.added[change.id] = change
      } else if (change instanceof StateChangeRemove) {
        this.localChanges.removed[change.id] = change
      } else if (change instanceof StateUpdate) {
        this.localChanges.updated[change.id] = change
      }
    }

    this.applyLocalChanges()

    this.update()
  }

  public setState(newState: ItemCollection) {
    if (this._isActive === false) {
      return
    }

    const valueWithLocalChanges = this.keepLocalChanges(newState)

    this.data = valueWithLocalChanges
    this.loading = false
    if (this.sideEffect) {
      this.sideEffect()
    }
    this.update()
  }

  public get state(): ItemCollection {
    return this.data
  }

  public clear() {
    this.data = this.getEmptyState()
    this.loading = false
    this.update()
  }

  public isEmpty(): boolean {
    return !this.data
  }

  public isLoading(): boolean {
    return this.loading
  }

  public setLoading() {
    this.loading = true
  }

  abstract keepLocalChanges(newState: ItemCollection): ItemCollection
  abstract applyLocalChanges(): void
  abstract handleStateAdd(newState: ItemCollection): ItemCollection
  abstract handleStateRemove(newState: ItemCollection): ItemCollection
  abstract handleStateUpdate(newState: ItemCollection): ItemCollection
  abstract getEmptyState(): ItemCollection
}
