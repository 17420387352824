import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import vueMaterialDesignIcons_Hh1jw1WRuBVc2lVLliX2y4krYUdNLMbYG7Eo9odYOhg from "/opt/atlassian/pipelines/agent/build/plugins/vueMaterialDesignIcons.ts";
import primeVue_XKbVWaNV1I_TSPRfv8EZam6b9xHupgfmphY7wJgcMo8 from "/opt/atlassian/pipelines/agent/build/plugins/primeVue.js";
import sentry_plNE0O6H4pKSVzRh2laUx68GUiCcwD7qqkrwmhuIaa8 from "/opt/atlassian/pipelines/agent/build/plugins/sentry.js";
import veeValidate_JcDSupqCJgR_YXJ6S7_DGq4DtRIf0nzILuAct4UeJuA from "/opt/atlassian/pipelines/agent/build/plugins/veeValidate.ts";
import vueDatepicker_VnnON6lZomwbWt2oi9kWoQ0bBTfhz7esgEaqHyyfg2A from "/opt/atlassian/pipelines/agent/build/plugins/vueDatepicker.ts";
import vueDompurifyHtml_JM4mMQRlzuNsJgypoOqS_WHAz2JOM4OtLaOvLyVl5dk from "/opt/atlassian/pipelines/agent/build/plugins/vueDompurifyHtml.js";
import vueToastification_Hv0fL5bwQHlmptVd6ZcskBKJ52fp9wGH2JATfD8_C1I from "/opt/atlassian/pipelines/agent/build/plugins/vueToastification.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  vueMaterialDesignIcons_Hh1jw1WRuBVc2lVLliX2y4krYUdNLMbYG7Eo9odYOhg,
  primeVue_XKbVWaNV1I_TSPRfv8EZam6b9xHupgfmphY7wJgcMo8,
  sentry_plNE0O6H4pKSVzRh2laUx68GUiCcwD7qqkrwmhuIaa8,
  veeValidate_JcDSupqCJgR_YXJ6S7_DGq4DtRIf0nzILuAct4UeJuA,
  vueDatepicker_VnnON6lZomwbWt2oi9kWoQ0bBTfhz7esgEaqHyyfg2A,
  vueDompurifyHtml_JM4mMQRlzuNsJgypoOqS_WHAz2JOM4OtLaOvLyVl5dk,
  vueToastification_Hv0fL5bwQHlmptVd6ZcskBKJ52fp9wGH2JATfD8_C1I,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]